<template>
  <UiPopup
    v-model="modelValue"
    :primary-button-text="isUpdating ? 'Save' : 'Create'"
    secondary-button-text="Cancel"
    size="large"
    compact
    :loading="isPending"
    @update:model-value="emits('update:modelValue', false)"
    @confirm="confirm"
  >
    <template #title>
      <span class="text-headline-4 text-left">{{ isUpdating ? 'Edit release' : 'Create new release' }}</span>
    </template>
    <div class="grid auto-rows-min grid-cols-2 gap-10 pt-4">
      <div class="flex h-full grow flex-col gap-2">
        <p class="text-body-2 text-left">Main release info:</p>
        <div class="flex flex-col gap-3 rounded-lg bg-black-03 p-4">
          <UiInputTextField
            id="release_name"
            v-model="form.name"
            name="release_name"
            placeholder="Release name"
            :error="useGetFieldErrors(v$, ['name'])"
          />

          <UiInputDatePicker
            id="release_released_at"
            v-model="form.released_at"
            placeholder="dd/mm/yyyy"
            :min-date="new Date()"
            name="release_released_at"
            :start-with-placeholder="!Boolean(release)"
            :error="useGetFieldErrors(v$, ['released_at'])"
          />

          <UiInputTimePicker
            id="release_time"
            v-model="form.time"
            name="release_time"
            icon="clock"
            icon-prefix
            placeholder="hh:mm"
            :error="useGetFieldErrors(v$, ['time'])"
          />

          <UiInputTextField
            id="release_link"
            v-model="form.link"
            name="release_link"
            icon="link"
            icon-prefix
            placeholder="Link"
            :error="useGetFieldErrors(v$, ['link'])"
          />

          <UiInputTextField id="release_note" v-model="form.note" name="release_note" placeholder="Note for release" />
        </div>
      </div>

      <div class="flex h-full grow flex-col gap-2">
        <p class="text-body-2 text-left">Set components and versions:</p>
        <div class="flex grow flex-col gap-3 rounded-lg bg-black-03 p-4">
          <div v-for="(component, index) in form.components" :key="index" class="flex items-center gap-3">
            <UiInputSelect
              :id="`type-${index}`"
              v-model="component.type_id"
              :loading="isLoadingComponentTypes"
              :name="`type-${index}`"
              placeholder="Select component"
              :items="releaseComponentTypesFormatted"
              :error="useGetFieldErrorsByIndex(v$, 'components', 'type_id', index)"
            />

            <div class="flex max-w-52 items-center gap-2">
              <UiInputTextField
                :id="`major-${index}`"
                v-model="component.major"
                :name="`major-${index}`"
                placeholder="0"
                :max-length="3"
                type="number"
                :error="useGetFieldErrorsByIndex(v$, 'components', 'major', index)"
              />

              <UiInputTextField
                :id="`minor-${index}`"
                v-model="component.minor"
                :name="`minor-${index}`"
                placeholder="0"
                :max-length="3"
                type="number"
                :error="useGetFieldErrorsByIndex(v$, 'components', 'minor', index)"
              />

              <UiInputTextField
                :id="`patch-${index}`"
                v-model="component.patch"
                :name="`patch-${index}`"
                placeholder="0"
                :max-length="3"
                type="number"
                :error="useGetFieldErrorsByIndex(v$, 'components', 'patch', index)"
              />
            </div>

            <UiButtonGhost
              :id="`remove-${index}`"
              icon
              type="ghost"
              :disabled="form.components.length === 1"
              class="!px-0"
              @click="removeComponent(index)"
            >
              <UiIcon name="big-close-circle-filled" size="xxs" />
            </UiButtonGhost>
          </div>
          <UiButtonGhost id="add-new-component" class="mt-2" :disabled="isAddButtonDisabled" @click="addNewComponent">
            <UiIcon name="big-add-circle" />
            Add component
          </UiButtonGhost>
        </div>
      </div>
    </div>
    <div class="mt-4 min-h-12">
      <Transition name="fade" mode="out-in">
        <div v-if="v$.$errors.length" class="flex gap-2 text-error-100">
          <UiIcon name="info-circle-filled" />

          <p class="text-caption-2 max-w-md text-left">
            It seems that not all the necessary data has been included for creating the release. <br />
            Each release must include at least one component and its version, the name, time, and date.
          </p>
        </div>
      </Transition>
    </div>
  </UiPopup>
</template>

<script setup lang="ts">
import useVuelidate from '@vuelidate/core'
import { helpers, required } from '@vuelidate/validators'
import { format, isValid, parse, parseISO } from 'date-fns'
import type { ReleaseDTO, ReleaseForm, ReleaseResponse } from '~/composables/api/releases'
import { useUiStore } from '~/store/ui'
import type { InputItem } from '~/types'

const props = defineProps<{
  release?: ReleaseResponse
}>()

const isUpdating = computed(() => Boolean(props.release))

const emits = defineEmits(['update:modelValue', 'input'])

const modelValue = defineModel<boolean>({ required: true })

const initialComponent: ReleaseComponent = {
  type_id: null,
  major: '',
  minor: '',
  patch: '',
}

const getParsedTime = (date: string) => {
  let result = null
  try {
    const dateObject = parseISO(date)

    if (!isValid(dateObject)) {
      throw new Error('Parsed date is not valid')
    }

    result = format(dateObject, 'HH:mm')
  } catch (error: any) {
    throw new Error(`Error when parsing time: ${error.message}`)
  }

  return result
}

const getParsedForm = (release: ReleaseResponse): ReleaseForm => {
  return {
    ...release,
    time: getParsedTime(release.released_at),
    components: release.components.map((component) => {
      const [major, minor, patch] = component.version.split('.')

      return {
        ...component,
        type_id: component.type.id,
        major,
        minor,
        patch,
      }
    }),
  }
}

const form = ref<ReleaseForm>(
  props.release
    ? getParsedForm(props.release)
    : {
        name: '',
        released_at: '',
        time: '',
        link: '',
        note: '',
        components: [
          {
            ...initialComponent,
          },
        ],
      }
)

const rules = computed(() => ({
  name: { required: helpers.withMessage(' ', required) },
  released_at: { required: helpers.withMessage(' ', required) },
  time: { required: helpers.withMessage(' ', required) },
  link: { required: helpers.withMessage(' ', required) },
  components: {
    $each: helpers.forEach({
      type_id: { required: helpers.withMessage(' ', required) },
      major: { required: helpers.withMessage(' ', required) },
      minor: { required: helpers.withMessage(' ', required) },
      patch: { required: helpers.withMessage(' ', required) },
    }),
  },
}))

const v$ = useVuelidate(rules, form)

const addNewComponent = () => {
  form.value.components.push({ ...initialComponent })
}

const isAddButtonDisabled = computed(() => {
  return releaseComponentTypes.value.length === form.value.components.length
})

const removeComponent = (index: number) => {
  if (form.value.components.length === 1) {
    return
  }

  form.value.components.splice(index, 1)
}

const getFormattedReleaseDate = (dateObject: string, time: string) => {
  let result = null

  try {
    const [date] = dateObject.split('T')

    const dateFormatted = `${date} ${time}`

    result = parse(dateFormatted, 'yyyy-MM-dd HH:mm', new Date())

    if (!isValid(result)) {
      throw new Error('Release date is not valid')
    }

    result = result.toISOString()
  } catch (error: any) {
    throw new Error(`Error when parsing release date: ${error.message}`)
  }

  return result
}

const getFormattedPayload = (form: ReleaseForm) => {
  return {
    id: form?.id,
    name: form.name,
    link: form.link,
    note: form.note,
    released_at: getFormattedReleaseDate(form.released_at, form.time),

    components: form.components.map(({ type_id: typeId, major, minor, patch }) => ({
      type_id: typeId!,
      version: `${major}.${minor}.${patch}`,
    })),
  }
}

const isPending = ref(false)

const createRelease = async (payload: ReleaseDTO) => {
  try {
    isPending.value = true

    await useCreateRelease(payload)

    emits('input')
    modelValue.value = false
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    isPending.value = false
  }
}

const updateRelease = async (payload: ReleaseDTO) => {
  try {
    isPending.value = true

    await useUpdateRelease(payload)

    emits('input')
    modelValue.value = false
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    isPending.value = false
  }
}

const confirm = async () => {
  const isValid = await v$.value.$validate()

  if (!isValid) {
    return
  }

  const payload = getFormattedPayload(form.value)

  if (isUpdating.value) {
    await updateRelease(payload)
  } else {
    await createRelease(payload)
  }
}

const releaseComponentTypes = ref<InputItem[]>([])

const releaseComponentTypesFormatted = computed<InputItem[]>(() =>
  releaseComponentTypes.value
    .map((item) => ({
      ...item,
      disabled: form.value.components.some((component) => component.type_id === item.value),
    }))
    .sort((a, b) => (a.disabled ? 1 : 0) - (b.disabled ? 1 : 0))
)

const isLoadingComponentTypes = ref(false)

const uiStore = useUiStore()

const getReleaseComponents = async () => {
  try {
    isLoadingComponentTypes.value = true

    releaseComponentTypes.value = useSerializeLibraryItems(await useReleaseComponentTypes())
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    isLoadingComponentTypes.value = false
  }
}

onMounted(async () => await getReleaseComponents())
</script>
