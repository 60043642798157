<template>
  <div
    :id="name"
    :ref="(el) => (refs[name].value = el as HTMLInputElement)"
    class="relative flex w-full flex-col items-start space-y-px"
  >
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>

    <UiInputTextField
      :model-value
      name="time"
      icon="clock"
      icon-prefix
      type="time"
      :placeholder="placeholder"
      :error
      @update:model-value="emits('update:modelValue', $event)"
    />
  </div>
</template>

<script setup lang="ts">
const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: any
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: string
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: null,
  label: '',
  placeholder: '',
  error: '',
})

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

const timeOptions = (() => {
  const result = []
  for (let hours = 0; hours < 24; hours++) {
    for (let minutes = 0; minutes < 60; minutes = minutes + 15) {
      result.push({
        text: `${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${hours >= 12 ? 'PM' : 'AM'}`,
        value: { hours, minutes },
      })
    }
  }
  return result
})()
</script>
<style scoped>
:deep() {
  input[type='time']::-webkit-calendar-picker-indicator {
    display: none;
  }
}
</style>
